import { SERVICE_REQUEST_TYPE } from "../constants";

export const formatDashboardLists = (list) => {
  return list?.map((dashboard) => {
    if (dashboard?.name.includes("Document")) {
      return { ...dashboard, name: "Document Dashboard" };
    }
    if (dashboard?.name.includes("Practice")) {
      return { ...dashboard, name: "Practice Dashboard" };
    }
    if (dashboard?.name.includes("Network")) {
      return { ...dashboard, name: "Network Dashboard" };
    }
    return dashboard;
  });
};

export const formatAppsetting = ({
  data,
  error,
  loading,
  exchangeTokenInfo,
  networkDashboards,
  setToken,
  isNetworkDashboardCallSkipped,
}) => {
  if (
    data?.client !== undefined &&
    data?.directoryProps !== undefined &&
    data?.exportConfig !== undefined &&
    data?.networks !== undefined &&
    data?.user !== undefined &&
    data?.clientDashboards !== undefined &&
    (networkDashboards?.networkDashboards !== undefined ||
      isNetworkDashboardCallSkipped)
  ) {
    const { client, directoryProps, exportConfig, networks, clientDashboards } =
      data;
    const sortedTenantNetworks = [...networks]
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map((net) => ({
        ...net,
        ...{ label: net?.name, value: net?.id },
      }));

    const primaryNetworkId = directoryProps?.primaryNetworkId;
    const privateNetworkId = directoryProps?.privateNetworkId;
    const tenantNetworkIds =
      networks
        ?.filter((net) => primaryNetworkId?.includes(net.id))
        ?.map((net) => net?.id) ||
      [] ||
      [];
    const allTenantNetworksIds = [
      ...tenantNetworkIds,
      ...(privateNetworkId ? [privateNetworkId] : []),
    ];
    const hasMoreThanOneNetwork = primaryNetworkId?.length > 1;

    const authoritiesList = exchangeTokenInfo.authorities;
    const roles = {
      adminTab: Boolean(
        authoritiesList.includes("ROLE_SUPERUSER") ||
          authoritiesList.includes("ROLE_TENANTADMIN")
      ),
      readOnly: Boolean(authoritiesList.includes("ROLE_READONLY")),
    };

    const updatedClientDashboard = clientDashboards.map((dashboard) => ({
      ...dashboard,
      isNetworkDashboard: false,
    }));

    const updatedNetworkDashboard =
      networkDashboards?.networkDashboards
        ?.filter((item) =>
          clientDashboards?.every((dashboard) => dashboard?.id !== item.id)
        )
        .map((d) => ({ ...d, isNetworkDashboard: true })) || [];

    const dashboardLists = [
      ...new Map(
        [...updatedClientDashboard, ...updatedNetworkDashboard].map((item) => [
          item.id,
          item,
        ])
      ).values(),
    ];

    return {
      exportConfig,
      error,
      roles,
      clientName: client?.name,
      clientMode: client?.mode,
      isDefaultServiceRequestType:
        client?.serviceRequestType === SERVICE_REQUEST_TYPE.DEFAULT,
      loading,
      primaryNetworkId: primaryNetworkId ?? [],
      privateNetworkId: privateNetworkId ?? "",
      tenantNetworkIds,
      setToken,
      tenantNetworks: networks,
      dashboardLists: formatDashboardLists(dashboardLists),
      hasMoreThanOneNetwork,
      allTenantNetworksIds,
      sortedTenantNetworks:
        sortedTenantNetworks?.filter((net) =>
          primaryNetworkId?.includes(net.id)
        ) || [],
    };
  } else {
    return {
      allTenantNetworksIds: [],
      primaryNetworkId: [],
      setToken,
    };
  }
};
